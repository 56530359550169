<template>
  <div class="fixed top-0 left-0 bg-neutral-gray flex flex-col justify-between items-center h-screen w-full">
    <div />
    <div class="text-gray-400">
      <fa-icon
        icon="spinner"
        spin
        size="3x"
      />
    </div>
    <img
      src="@assets/img/logo/previz-logotype-black.svg"
      alt="Previz Logo"
      class="logo w-24 mb-10 opacity-25"
    >
  </div>
</template>
<script>
export default {
  name: 'AppLoader'
}
</script>
